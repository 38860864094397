import React from 'react';
import { Link } from 'gatsby';

import config from '../../config';
import Layout from '../components/Layout';

const thanks = () => (
  <Layout>
    <div id="wrapper">
      <section id="banner">
        <div className="inner book-hero">
          <div className="hero-section">
            <h1 className="book-heading">{config.heading}</h1>

            <h2>{config.subHeading}</h2>
            <hr />
            <h3 className="error-head">
              Thank you! I will be in touch!
            </h3>
            <Link to="/" className="stripped hero-link">
              Return home &rarr;
            </Link>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default thanks;
